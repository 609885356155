import { getFormattedPrice } from '../../../../sharedModules/getFormattedPrice';
import { getPriceFromDeal } from '../../../../sharedModules/getPriceFromDeal';
import { WidgetProps } from '../../../types/WidgetProps';
import { getFirstNotNullishProperty } from '../../../utils/getFirstNotNullishProperty';
import { DealWidgetTitleStructure, DealWidgetTitleStructureProperties } from '../types';

import { getMerchantName } from './getMerchantName';
import { shouldHideDealWidgetTitle } from './shouldHideDealWidgetTitle';

interface RawDealWidgetTitleData {
  dataLinkMerchant?: WidgetProps['dataLinkMerchant'];
  deal: WidgetProps['dealData']['deals'][number];
  language: WidgetProps['language'];
  translate: WidgetProps['translate'];
  showPriceRounded: WidgetProps['showPriceRounded'];
  editorialMerchantName: WidgetProps['editorialMerchantName'];
  modelBrand: WidgetProps['modelBrand'];
  modelProductName: WidgetProps['modelProductName'];
}

export interface DealWidgetTitleData {
  modifiedDataLinkMerchant?: string;
  formattedPrice: string | null;
  formattedWasPrice: string | null;
  shouldHideDealWidgetTitle: boolean;
  shouldDisplayModelBrand: boolean;
  isWasPriceFirst: boolean;
  isPriceFirst: boolean;
  isMerchantFirst: boolean;
}

export const prepareDataForDealWidgetTitle = ({
  deal,
  dataLinkMerchant,
  editorialMerchantName,
  translate,
  language,
  showPriceRounded,
  modelBrand,
  modelProductName,
}: RawDealWidgetTitleData): DealWidgetTitleData => {
  const modifiedDataLinkMerchant = getMerchantName(
    dataLinkMerchant?.name || '',
    editorialMerchantName,
  );

  const formattedPrice = getFormattedPrice({
    price: getPriceFromDeal(deal, 'retail'),
    currencyIso: deal.offer.currency_iso,
    type: 'retail',
    locale: language,
    translate,
    showPriceRounded,
  });

  const formattedWasPrice = getFormattedPrice({
    price: getPriceFromDeal(deal, 'wasPrice'),
    currencyIso: deal.offer.currency_iso,
    type: 'wasPrice',
    locale: language,
    translate,
    showPriceRounded,
  });

  const firstInTitle = getFirstNotNullishProperty<Partial<DealWidgetTitleStructure>>({
    modelProductName,
    formattedWasPrice,
    formattedPrice,
  });

  const isWasPriceFirst =
    firstInTitle?.name === DealWidgetTitleStructureProperties.FORMATTED_WAS_PRICE;
  const isPriceFirst = firstInTitle?.name === DealWidgetTitleStructureProperties.FORMATTED_PRICE;
  const isMerchantFirst =
    firstInTitle?.name === DealWidgetTitleStructureProperties.DATA_LINK_MERCHANT;

  return {
    modifiedDataLinkMerchant,
    formattedPrice,
    formattedWasPrice,
    shouldHideDealWidgetTitle: shouldHideDealWidgetTitle({
      modelBrand,
      modelProductName,
      formattedWasPrice,
      formattedPrice,
    }),
    shouldDisplayModelBrand: Boolean(modelBrand && modelProductName),
    isWasPriceFirst,
    isPriceFirst,
    isMerchantFirst,
  };
};
